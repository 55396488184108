@use "./config" as *;

.scorecard-container {
  // max-width: var(--container-max-width);
  margin: 0 auto;
  position: relative;
  box-shadow: 0 0.3rem 1rem rgba(var(--black-color-rgb), 0.25);

  &:before {
    content: "";
    background: url(/static-assets/images/cssimages/kabaddi-bg.png?v=#{$image-version});
    width: var(--window-inner-width);
    margin: 0 -1rem;
    position: absolute;
    height: 100%;
    z-index: -1;
    opacity: 0.5;
    left: calc((var(--window-inner-width) - var(--container-max-width)) / 2 * -1);
  }
  .graph-listing {
    padding: var(--full-space);
    border-bottom: 0.1rem solid rgba(var(--black-color-rgb), 0.3);
    @include flex-config(flex, null, space-around, null);

    .graph-label,
    .raid-label {
      font-size: 1.2rem;
      font-family: $font-bold;
      color: var(--kabaddi-secondary-color);
    }

    .team-a,
    .unsuccessful {
      text-align: center;

      .top-circle {
        stroke: var(--kabaddi-accent-color);
      }
    }

    .team-b,
    .successful {
      text-align: center;

      .top-circle {
        stroke: var(--kabaddi-primary-color);
      }
    }

    .empty {
      text-align: center;
      .top-circle {
        stroke: rgba(var(--black-color-rgb), 0.3);
      }
    }

    circle {
      width: 100%;
      transform-origin: center;
      @include rotate(-90);
    }

    .graph-wrap {
      width: 10rem;
      height: 10rem;
      position: relative;
    }

    .graph-count {
      font-size: 1.6rem;
      font-family: $font-bold;
      text-align: center;
      @include position(50%, 0, null, 0);
      @include transform(translateY(-50%));
    }

    .circle-svg {
      width: 100%;
      height: auto;
      overflow: auto;
    }

    .bottom-circle {
      stroke: var(--light-grey-color);
    }

    .top-circle {
      stroke-width: 2;
      animation: fill 1s reverse;
    }

    .bottom-circle {
      stroke-width: 2;
    }
  }

  .scorecard-sub-tab {
    border-bottom: 0.1rem solid rgba(var(--black-color-rgb), 0.3);

    .tab-name {
      border-right: none;

      &:not(:last-child) {
        border-right: none;
      }

      &.tab-active,
      &:hover {
        &::before {
          height: 0.2rem;
          bottom: -1rem;
        }
      }
    }
  }
}

//scorecard
.scorecard-tab-container {
  .tab-name {
    width: 50%;
  }
  .table {
    &-row {
      height: 6rem;
      @include flex-config(flex, null, flex-start, center);

      &:not(:last-child) {
        border-bottom: 0.1rem solid rgba(var(--black-color-rgb), 0.1);
      }
    }

    &-data {
      width: 20%;
      flex-basis: 20%;
      text-align: center;

      &.label {
        width: 60%;
        flex-basis: 60%;
        text-align: left;
      }

      &.touch-points,
      &.bonus-points {
        display: none;
      }
    }

    &-head {
      .table-row {
        height: 4rem;
        background-color: var(--kabaddi-secondary-color);
        padding: 0 var(--full-space);
      }

      .table-data {
        color: var(--white-color);
        line-height: 1;
      }
    }

    &-body {
      padding: 0 var(--full-space);

      .table-data {
        &.tackle-points {
          span {
            position: relative;
          }
        }
      }
    }

    &-content {
      .raid,
      .raids {
        &-info {
          height: 6rem;
          background-color: var(--kabaddi-header-bg);
          @include flex-config(flex, null, center, center);

          .raid-count {
            color: var(--white-color);
            padding: 0 2rem;
            @include flex-config(flex, column, center, center);
          }

          .total-raid {
            border-right: 0.2rem solid rgba(var(--white-color-rgb), 0.2);
            @include flex-config(null, null, null, flex-end);
          }

          .successful-raid {
            @include flex-config(null, null, null, flex-start);
          }

          .count {
            font-family: $font-bold;
            font-size: 2.2rem;
          }

          .label {
            font-size: 1.1rem;
            text-transform: uppercase;
          }
        }

        &-graph {
          background-color: rgba(var(--light-grey-color-rgb), 0.5);
        }

        &-sequence {
          padding: var(--full-space);

          .head {
            color: var(--copylink-color);
            font-size: 1rem;
            font-family: $font-bold;
            margin-bottom: var(--half-space);
          }

          .sequence-listing {
            flex-wrap: wrap;
            @include flex-config(flex, null, null, null);
          }

          .sequence-item {
            width: 2rem;
            height: 2rem;
            margin: var(--half-space) var(--half-space) 0 0;

            &.successful {
              background-color: var(--kabaddi-primary-color);
            }

            &.unsuccessful {
              background-color: var(--kabaddi-accent-color);
            }

            &.empty {
              background-color: rgba(var(--black-color-rgb), 0.6);
            }
          }
        }
      }
    }
  }

  .has-accordion,
  .active {
    .player-name {
      position: relative;
      &:after {
        content: " ";
        font-family: $font-icon;
        // display: block;
        font-size: 0.8rem;
        color: var(--kabaddi-accent-color);
        cursor: pointer;
        margin-left: 0.5rem;
        // @include position(50%, -2.2rem, null, null);
        // @include transform(translateY(-50%));
      }
    }
  }
  .has-accordion {
    .player-name {
      &::after {
        content: "\e827";
      }
    }
  }
  .active {
    .player-name {
      &::after {
        content: "\e82a";
      }
    }
  }
  .super-tackle {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    @include position(null, null, 1rem, 1rem);
    @include flex-config(flex, null, center, center);
    &:before {
      content: "";
      background: url("/static-assets/images/cssimages/svg/super-tackle.svg?v=#{$image-version}") no-repeat center;
      background-size: contain;
      width: 1.1rem;
      height: 1.6rem;
    }
    &:hover {
      .tackle-tooltip {
        display: block;
      }
    }
  }
  .tackle-tooltip {
    display: none;
    background-color: var(--kabaddi-accent-color);
    color: var(--white-color);
    padding: 1rem;
    width: max-content;
    @include position(null, null, 3rem, 50%);
    @include transform(translateX(-50%));
    &::before {
      content: "";
      width: 0;
      height: 0;
      border-left: 0.8rem solid transparent;
      border-right: 0.8rem solid transparent;
      border-top: 0.8rem solid var(--kabaddi-accent-color);
      margin: 0 auto;
      @include position(null, 0, -0.8rem, 0);
    }
  }
}

//play by play
.playbyplay-tab-container {
  .playbyplay-list {
    .playbyplay-item {
      border-bottom: 0.05rem solid rgba(var(--black-color-rgb), 0.2);

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .container-wrap {
    padding: 0 var(--full-space);
  }

  .item-wrap {
    padding: var(--full-space) 0;
    @include flex-config(flex, null, null, center);
  }

  .col {
    &-one,
    &-two,
    &-three {
      @include flex-config(flex, column, center, null);
    }

    &-one {
      font-size: 1.4rem;
      font-family: $font-bold;
      white-space: nowrap;
      color: var(--kabaddi-secondary-color);
      margin-right: var(--full-space);
    }

    &-two {
      span {
        position: relative;
        width: 3.8rem;
        height: 3.8rem;
        font-family: $font-bold;
        background-color: rgba(var(--black-color-rgb), 0.1);
        @include flex-config(flex, row, center, center);
      }
    }

    &-three {
      margin-left: var(--full-space);

      .note {
        font-size: 1.2rem;
        font-family: $font-regular;
        color: rgba(var(--kabaddi-secondary-color-rgb), 0.6);
      }

      .text {
        font-size: 1.4rem;
        font-family: $font-bold;
        margin-bottom: var(--half-space);
        color: var(--kabaddi-secondary-color);
      }

      .substitution-players-info {
        font-size: 1.4rem;

        .player-out,
        .player-in {
          display: block;
          position: relative;
          padding: var(--half-space) var(--full-space) 0;

          &:before {
            content: "\e818";
            position: absolute;
            display: block;
            font-family: $font-icon;
            transform: rotate(90deg);
            color: var(--kabaddi-accent-color);
            @include position(50%, null, null, 0);
          }
        }

        .player-in {
          &:before {
            content: "\e818";
            position: absolute;
            display: block;
            font-family: $font-icon;
            transform: rotate(-90deg);
            color: var(--live-color);
            @include position(50%, null, null, 0);
          }
        }

        .status {
          font-family: $font-bold;
          color: var(--kabaddi-secondary-color);
        }
      }
    }
  }

  .yellow-card,
  .green-card,
  .red-card,
  .substitution,
  .time {
    .icon {
      &:before {
        content: "";
        width: 1.4rem;
        height: 1.9rem;
        @include center(vertical);
        display: block;
      }
    }
  }

  .yellow-card {
    .icon {
      &:before {
        background-color: var(--kabaddi-primary-color);
      }
    }
  }

  .green-card {
    .icon {
      &:before {
        background-color: var(--live-color);
      }
    }
  }

  .red-card {
    .icon {
      &:before {
        background-color: var(--lost-color);
      }
    }
  }

  .substitution {
    .icon {
      &:before {
        background: url("/static-assets/images/cssimages/svg/substitute-player.svg?v=#{$image-version}") no-repeat
          center;
        background-size: contain;
        width: 100%;
      }
    }
  }

  .time {
    .icon {
      background-color: var(--black-color);

      &:before {
        background: url("/static-assets/images/cssimages/svg/time-out.svg?v=#{$image-version}") no-repeat center;
        background-size: contain;
        width: 100%;
      }
    }
  }
}

//match stats
.matchstats-tab-container {
  padding: var(--full-space);

  .match-teams {
    padding: var(--full-space) 0;
    margin-bottom: var(--full-space);
    border-bottom: 0.1rem solid rgba(var(--black-color-rgb), 0.1);
    @include flex-config(flex, null, null, center);

    .legend {
      display: none;
    }

    .sub-title {
      font-size: 1.1rem;
      font-family: $font-bold;
      width: 5rem;
      text-align: center;
      flex-shrink: 0;
      margin: 0 var(--half-space);
    }

    img {
      width: 3rem;
      height: 3rem;
      // border: 0.1rem solid rgba(var(--black-color-rgb), 0.2);
      // border-radius: 100%;
    }

    .text {
      width: calc(100% - 3rem);
      font-size: 1.4rem;
      font-family: $font-bold;
      padding: 0 var(--half-space);
    }

    .team {
      width: calc(50% - 2.5rem);
      @include flex-config(flex, null, null, center);

      &-a {
        @include flex-config(null, row-reverse, null, null);

        .text {
          text-align: right;
        }

        img {
          border-color: var(--kabaddi-accent-color);
        }
      }

      &-b {
        img {
          border-color: var(--kabaddi-primary-color);
        }
      }
    }
  }

  .graph-listing {
    @include flex-config(flex, null, space-around, null);

    .team-a,
    .unsuccessful {
      text-align: center;

      .top-circle {
        stroke: var(--kabaddi-accent-color);
      }
    }

    .team-b {
      text-align: center;

      .top-circle {
        stroke: var(--kabaddi-primary-color);
      }
    }

    circle {
      width: 100%;
      transform-origin: center;
      @include rotate(-90);
    }

    .graph {
      &-wrap {
        width: 10rem;
        height: 10rem;
        position: relative;
      }
      &-count {
        font-size: 1.6rem;
        font-family: $font-bold;
        text-align: center;
        @include position(50%, 0, null, 0);
        @include transform(translateY(-50%));
      }
    }

    .circle-svg {
      width: 100%;
      height: auto;
      overflow: auto;
    }

    .bottom-circle {
      stroke: var(--light-grey-color);
    }

    .top-circle {
      stroke-width: 2;
      animation: fill 1s reverse;
    }

    .bottom-circle {
      stroke-width: 2;
    }
  }

  .progressbar-listing {
    .label-wrap {
      font-size: 1.2rem;
      margin-bottom: var(--half-space);
      font-family: $font-medium;
      @include flex-config(flex, null, space-between, null);
    }

    .progress-view {
      background-color: var(--light-grey-color);
      height: 0.5rem;
      @include flex-config(flex, null, null, null);
    }

    .team-bar {
      width: 50%;
      height: 100%;
      @include flex-config(flex, null, null, null);

      &.team-a-bar {
        position: relative;
        @include flex-config(null, null, flex-end, null);

        span {
          background-color: var(--kabaddi-accent-color);
        }

        &::after {
          content: "";
          width: 0.1rem;
          height: 1.5rem;
          background-color: var(--kabaddi-secondary-color);
          position: absolute;
          top: 50%;
          @include transform(translateY(-50%));
        }
      }

      &.team-b-bar {
        position: relative;
        @include flex-config(null, null, null, null);

        span {
          background-color: var(--kabaddi-primary-color);
        }
      }
    }

    .progress-wrap {
      margin: calc(2 * var(--full-space)) 0;
    }
  }
}

@media screen and (min-width: $tablet-min-width) {
  // .tab-container {
  //   width: var(--container-max-width);
  //   margin: auto;
  // }

  //scorecard
  .scorecard-tab-container {
    .table {
      &-data {
        width: 10%;
        flex-basis: 10%;

        &.label {
          width: 50%;
          flex-basis: 50%;
        }

        &.touch-points,
        &.bonus-points {
          display: block;
        }
      }

      &-content {
        .raid {
          &-info {
            height: 7.5rem;
            margin-bottom: var(--full-space);

            .count {
              font-size: 2.8rem;
            }

            .label {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }

  //play by play
  .playbyplay-tab-container {
    .col-three {
      .substitution-players-info {
        .player-in,
        .player-out {
          display: unset;
        }
      }
    }
  }

  //match stats
  .matchstats-tab-container {
    .match-teams {
      img {
        width: 5.4rem;
        height: 5.4rem;
      }

      .sub-title {
        margin: 0 var(--full-space);
      }
    }

    .graph-listing {
      @include flex-config(null, null, center, null);

      .graph {
        &-item {
          margin: 0 calc(var(--full-space) * 2);
        }

        &-label {
          font-family: $font-medium;
        }
      }
    }

    .progressbar-listing {
      padding: 0 calc(3 * var(--full-space));

      .progress {
        &-wrap {
          position: relative;
          margin: calc(4 * var(--full-space)) 0;

          .label-wrap {
            position: absolute;
            width: 100%;
            top: -1rem;

            .label {
              font-size: 1.8rem;

              &:nth-child(2) {
                margin-top: -2rem;
                font-size: 1.6rem;
              }
            }
          }
        }

        &-view {
          width: calc(100% - 10rem);
          margin: 0 auto;
        }
      }
    }
  }
}
